import { Button, Container, Typography } from 'amphitheatre';
import { Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { FaExclamationCircle } from 'react-icons/fa';

import { TLCircle } from '../components/Background';
import Image from '../components/Image';
import Layout from '../components/Layout';
import AboutUs1 from '../images/aboutus1.jpg';
import LogoDandelions from '../images/LogoDandelion.png';
import LogoMetakosmos from '../images/LogoMetakosmos.png';
import LogoOrbitOz from '../images/LogoOrbitOz.png';
import LogoSpaceIndustries from '../images/LogoSpaceIndustries.png';
import LogoSperoSpace from '../images/LogoSperoSpace.png';
import LogoSpiralBlue from '../images/LogoSpiralBlue.png';
import LogoAnt61 from '../images/LogoANT61.png';
import LogoTCG from '../images/LogoTCG.png';
import LogoSaber from '../images/saber.png';
import {
  AboutUsContent,
  Divider,
  Icon,
  Images,
  MoreInfo,
  Separator,
  Sponsor,
  Sponsors,
  TeamList,
} from '../pages-styles/aboutus.styled';
import * as Styled from '../pages-styles/shared.styled';
import ApplicationFile from '../files/wolfpackApplication.pdf';

const { Header, Paragraph } = Typography;

const AboutUs: React.FunctionComponent = () => {
  return (
    <Layout>
      <Helmet>
        <title>Wolfpack - About Us</title>
      </Helmet>
      <>
        <TLCircle />
      </>
      <Styled.Container>
        <AboutUsContent>
          <div className="left-content">
            <Header className="page-header">OUR MISSION</Header>
            <Paragraph style={{ textAlign: 'justify' }}>
              The Wolfpack Space Hub mission is to incubate early stage
              Australian space startups from concept to on-orbit flight. Like
              any incubator we provide conventional support to startups such as
              advisory services and industry relationships. But as a space
              incubator we provide specific support and training for space
              companies that is not found in a traditional startup house. This
              includes deep-tech technical support to fly in space with their
              own hardware, satellite operations support, and a shared vision of
              space amongst our members. The Wolfpack focuses on customer
              acquisition and we actively collaborate as a pack for better
              growth of our members.
            </Paragraph>
            <Image
              caption="Spiral Blue's Space Edge in development"
              imgStyle={{ objectFit: 'cover' }}
              src={AboutUs1}
            />
          </div>
          <div className="right-content">
            <Header className="page-header">ABOUT US</Header>
            <Paragraph style={{ textAlign: 'justify' }}>
              The Wolfpack Space Hub is a joint venture between Saber
              Astronautics and the TCG Corporation designed to build “upstream”
              Australian space manufacturers. Located near the heart of Sydney
              in Waterloo, Wolfpack offers start-up incubation, training and
              support to bring your napkin sketch to life.
            </Paragraph>

            <Header level={4}>
              Are you a space startup with ambition to grow and manufacture in
              Australia?
            </Header>
            <br />
            <a
              href="https://au1.documents.adobe.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhDDRAXU4UcNsQ_vnDyQXAl0_oyikYGg8dmNSWNm71KoHgAVjU82xbfvMtVSRKx-vBs*"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button fill>Apply Now</Button>
            </a>
            <Paragraph>Funds generated for our members: $3m</Paragraph>
            <Divider />
          </div>
        </AboutUsContent>
        <Separator />
      </Styled.Container>
      <TeamList>
        <Container size="lg">
          <br />
          <Header className="page-header">WOLFPACK COMPANIES</Header>
          <Paragraph>
            The Wolfpack team is currently occupied by a number of Australia’s
            up-and-coming space startups, all with a focus on creation.
          </Paragraph>
          <Images>
            <Link to="/startups">
              <img src={LogoOrbitOz} alt="OrbitOz" />
            </Link>
            <Link to="/startups">
              <img src={LogoSperoSpace} alt="Sperospace" />
            </Link>
            <Link to="/startups">
              <img src={LogoSpiralBlue} alt="Spiral Blue" />
            </Link>
            <Link to="/startups">
              <img src={LogoDandelions} alt="Dandelions.Cloud" />
            </Link>
            <Link to="/startups">
              <img src={LogoSpaceIndustries} alt="Space Industries" />
            </Link>
            <Link to="/startups">
              <img src={LogoMetakosmos} alt="Metakosmos" />
            </Link>
            <Link to="/startups">
              <img src={LogoAnt61} alt="ANT61 Robotics" />
            </Link>
            <Link to="/startups" style={{ textDecoration: 'none' }}>
              <MoreInfo>
                <span>Click For More Information</span>
                <Icon>
                  <FaExclamationCircle />
                </Icon>
              </MoreInfo>
            </Link>
          </Images>
          <Paragraph>
            For more information about these startups,
            <Link to="/startups">
              <Button link style={{ padding: '0 0.25rem' }}>
                Click Here!
              </Button>
            </Link>
          </Paragraph>
          <br />
          <br />
          <br />
          <Header className="page-header">FOUNDERS</Header>
          <Sponsors>
            <Sponsor>
              <a
                href="https://saberastro.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={LogoSaber} alt="Saber Astronautics" />
              </a>
              <div>
                <b>Saber Astronautics</b> is a recognised leader in the rapidly
                growing Australian space industry, a trusted technology supplier
                for space products to the Australian military, Australian Space
                Agency, NASA, and US Space Forces. Saber Astronautics is highly
                active in the NewSpace community with programs aimed to
                democratise space and make it more accessible to new members.
                Saber has built, chaired, and mentored nearly a dozen space
                startups in Australia and the USA.
              </div>
            </Sponsor>
            <Sponsor>
              <div>
                <img src={LogoTCG} alt="TCG" />
              </div>
              <div>
                <b>TCG</b> is a recognised leader in incubating and scaling
                businesses, having floated, some of the 65 companies in the TCG
                Group on the Australian Stock Exchange as TechComm Group
                Limited, later renamed Utility Computer Services (UXC). In 2016,
                UXC was acquired by CSC for $430 million. Another former TCG
                company floated on the New York Stock Exchange in November 1997
                for US$600m (AU$1 billion), making it the largest technology
                company to be established in Australia until that time. Today
                the TCG companies, and entities with TCG roots, employ well over
                6000 people with a turnover in excess of $1.5 billion annually.
              </div>
            </Sponsor>
          </Sponsors>
        </Container>
      </TeamList>
    </Layout>
  );
};

export default AboutUs;

import styled from 'styled-components';

export const AboutUsContent = styled.div`
  .left-content {
    padding-bottom: 1rem;
  }

  @media only screen and (min-width: 1024px) {
    display: flex;

    div {
      flex-grow: 1;
      flex-basis: 50%;
    }

    .left-content {
      padding-right: 5rem;
    }
  }

  @media only screen and (min-width: 1200px) {
    .left-content {
      padding-right: 10rem;
    }
  }
`;

export const Divider = styled.div`
  background-color: ${({ theme }): string => theme.primary400};
  margin: 2rem 0;
  padding: 0.25rem;
`;

export const Images = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  img {
    height: 96px;
    width: 96px;
    margin: 1rem 0.25rem;

    @media only screen and (min-width: 768px) {
      height: 128px;
      width: 128px;
    }
  }
`;

export const Icon = styled.div`
  font-size: 1rem;

  @media only screen and (min-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const MoreInfo = styled.div`
  border: 2px solid white;
  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;

  color: white;
  font-family: ${({ theme }): string => theme.fontBody};
  font-size: 0.75rem;
  font-weight: bold;

  text-align: center;

  height: 96px;
  width: 96px;
  margin: 1rem 0;

  @media only screen and (min-width: 768px) {
    height: 128px;
    width: 128px;
  }
`;

export const TeamList = styled.div`
  background-color: ${({ theme }): string => theme.primary100};
  padding: 1rem;
`;

export const Separator = styled.div`
  @media only screen and (min-width: 768px) {
    padding: 1rem;
  }
`;

export const Sponsors = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 2rem;
`;

export const Sponsor = styled.div`
  color: ${({ theme }): string => theme.primary800};
  display: flex;
  align-items: center;
  flex-flow: column;

  font-family: ${({ theme }): string => theme.fontBody};

  margin-bottom: 2rem;

  img {
    margin: 0 0 1rem 0;
    width: 128px;
  }

  @media only screen and (min-width: 768px) {
    flex-flow: row;

    img {
      margin: 0 1rem 0 0;
      width: 128px;
    }
  }
`;
